import { Component, Input } from '@angular/core';
import { ContactFormsService } from '../../services/contact-forms.service';

@Component({
  selector: 'app-form-submit-state',
  templateUrl: './form-submit-state.component.html',
  styleUrls: ['./form-submit-state.component.scss'],
})
export class FormSubmitStateComponent {
  @Input() messageSuccess!: boolean;

  constructor(private contactFormService: ContactFormsService) {}

  reset() {
    this.contactFormService.resetForm.emit(true);
  }
}
